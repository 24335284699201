import mbtiJson from "../json/mbitiJson";

const mbtiTypeScore = (me, you) => {
	let check = 0;
	let result = mbtiJson[`${me.mbti}${me.gender}${you.mbti}${you.gender}`];
	// INFPfemaleISTJmale;
	// OfemaleOmale
	if (!result) {
		result = mbtiJson[`${you.mbti}${you.gender}${me.mbti}${me.gender}`];
		check = 1;
	}
	return [result, check];
};

export default mbtiTypeScore;
