const zodiacSignJson = {
	mousemalemousemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"불화가 없고 대체로 협력적인 관계이다. 상당히 괜찮은 편이라 한 침을 이룰 수 있다.",
		score: 75,
	},
	mousemalemousefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"불화가 없고 대체로 협력적인 관계이다. 상당히 괜찮은 편이라 한 침을 이룰 수 있다.",
		score: 75,
	},
	mousefemalemousefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"불화가 없고 대체로 협력적인 관계이다. 상당히 괜찮은 편이라 한 침을 이룰 수 있다.",
		score: 75,
	},
	mousemaleoxmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"결혼상대나 사업상대로 아주 잘 어울리는 짝이다. 서로 사랑하고 이해하고 공감하는 관계이다.",
		score: 100,
	},
	mousemaleoxfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"결혼상대나 사업상대로 아주 잘 어울리는 짝이다. 서로 사랑하고 이해하고 공감하는 관계이다.",
		score: 100,
	},
	mousefemaleoxmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"결혼상대나 사업상대로 아주 잘 어울리는 짝이다. 서로 사랑하고 이해하고 공감하는 관계이다.",
		score: 100,
	},
	mousefemaleoxfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"결혼상대나 사업상대로 아주 잘 어울리는 짝이다. 서로 사랑하고 이해하고 공감하는 관계이다.",
		score: 100,
	},
	mousemaletigermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"그저 그런 대로 넘어가는 관계이다. 불화는 없지만 또 특별한 매력도 못 느끼는 관계이다. 기껏해야 원만한 관계를 유지할 상태이다.",
		score: 75,
	},
	mousemaletigerfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"그저 그런 대로 넘어가는 관계이다. 불화는 없지만 또 특별한 매력도 못 느끼는 관계이다. 기껏해야 원만한 관계를 유지할 상태이다.",
		score: 75,
	},
	mousefemaletigermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"그저 그런 대로 넘어가는 관계이다. 불화는 없지만 또 특별한 매력도 못 느끼는 관계이다. 기껏해야 원만한 관계를 유지할 상태이다.",
		score: 75,
	},
	mousefemaletigerfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"그저 그런 대로 넘어가는 관계이다. 불화는 없지만 또 특별한 매력도 못 느끼는 관계이다. 기껏해야 원만한 관계를 유지할 상태이다.",
		score: 75,
	},
	mousemalerabbitmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"우호적인 관계이다. 같이 지내는데 별다른 어려움이 없는 상대이며, 커다란 의사충돌은 없는 편이다.",
		score: 50,
	},
	mousemalerabbitfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"우호적인 관계이다. 같이 지내는데 별다른 어려움이 없는 상대이며, 커다란 의사충돌은 없는 편이다.",
		score: 50,
	},
	mousefemalerabbitmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"우호적인 관계이다. 같이 지내는데 별다른 어려움이 없는 상대이며, 커다란 의사충돌은 없는 편이다.",
		score: 50,
	},
	mousefemalerabbitfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"우호적인 관계이다. 같이 지내는데 별다른 어려움이 없는 상대이며, 커다란 의사충돌은 없는 편이다.",
		score: 50,
	},
	mousemaledragonmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 상대이다. 결혼이나 동업을 하면 성공과 행복과 번영을 모두 얻을 수 있는 상대이다.",
		score: 100,
	},
	mousemaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 상대이다. 결혼이나 동업을 하면 성공과 행복과 번영을 모두 얻을 수 있는 상대이다.",
		score: 100,
	},
	mousefemaledragonmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 상대이다. 결혼이나 동업을 하면 성공과 행복과 번영을 모두 얻을 수 있는 상대이다.",
		score: 100,
	},
	mousefemaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 상대이다. 결혼이나 동업을 하면 성공과 행복과 번영을 모두 얻을 수 있는 상대이다.",
		score: 100,
	},
	mousemalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"상당한 정도의 협력이 가능한 상대이다. 상호간의 존경과 이해가 있으면 아주 좋은 짝이 될 수 있다. 상당히 성공할 만한 관계이다.",
		score: 75,
	},
	mousemalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"상당한 정도의 협력이 가능한 상대이다. 상호간의 존경과 이해가 있으면 아주 좋은 짝이 될 수 있다. 상당히 성공할 만한 관계이다.",
		score: 75,
	},
	mousefemalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"상당한 정도의 협력이 가능한 상대이다. 상호간의 존경과 이해가 있으면 아주 좋은 짝이 될 수 있다. 상당히 성공할 만한 관계이다.",
		score: 75,
	},
	mousefemalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"상당한 정도의 협력이 가능한 상대이다. 상호간의 존경과 이해가 있으면 아주 좋은 짝이 될 수 있다. 상당히 성공할 만한 관계이다.",
		score: 75,
	},
	mousemalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"불행한 관계이다. 사랑이나 사업관계로 만나면 심한 불화가 일어날 상대이다. 서로의 개성이 충돌하며 경쟁심이 첨예해지는 상대이다.",
		score: 50,
	},
	mousemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"불행한 관계이다. 사랑이나 사업관계로 만나면 심한 불화가 일어날 상대이다. 서로의 개성이 충돌하며 경쟁심이 첨예해지는 상대이다.",
		score: 50,
	},
	mousefemalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"불행한 관계이다. 사랑이나 사업관계로 만나면 심한 불화가 일어날 상대이다. 서로의 개성이 충돌하며 경쟁심이 첨예해지는 상대이다.",
		score: 50,
	},
	mousefemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"불행한 관계이다. 사랑이나 사업관계로 만나면 심한 불화가 일어날 상대이다. 서로의 개성이 충돌하며 경쟁심이 첨예해지는 상대이다.",
		score: 50,
	},
	mousemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"공감대가 없는 적대적인 관계이다. 도대체 공통점이 없기 때문에 관계자체가 잘 되지 않는 상대이다.",
		score: 50,
	},
	mousemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"공감대가 없는 적대적인 관계이다. 도대체 공통점이 없기 때문에 관계자체가 잘 되지 않는 상대이다.",
		score: 50,
	},
	mousefemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"공감대가 없는 적대적인 관계이다. 도대체 공통점이 없기 때문에 관계자체가 잘 되지 않는 상대이다.",
		score: 50,
	},
	mousefemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"공감대가 없는 적대적인 관계이다. 도대체 공통점이 없기 때문에 관계자체가 잘 되지 않는 상대이다.",
		score: 50,
	},
	mousemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 관계이다. 서로 깊이 이해하기 때문에 사랑과 만족을 느낀다. 아주 성공적인 결합관계이다.",
		score: 100,
	},
	mousemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 관계이다. 서로 깊이 이해하기 때문에 사랑과 만족을 느낀다. 아주 성공적인 결합관계이다.",
		score: 100,
	},
	mousefemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 관계이다. 서로 깊이 이해하기 때문에 사랑과 만족을 느낀다. 아주 성공적인 결합관계이다.",
		score: 100,
	},
	mousefemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 관계이다. 서로 깊이 이해하기 때문에 사랑과 만족을 느낀다. 아주 성공적인 결합관계이다.",
		score: 100,
	},
	mousemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"뭔가 특별히 지속적인 관계가 맺어지지 않을 상대이다. 의사소통도 어렵기 때문에 서로 교제를 피하는 관계이다.",
		score: 50,
	},
	mousemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"뭔가 특별히 지속적인 관계가 맺어지지 않을 상대이다. 의사소통도 어렵기 때문에 서로 교제를 피하는 관계이다.",
		score: 50,
	},
	mousefemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"뭔가 특별히 지속적인 관계가 맺어지지 않을 상대이다. 의사소통도 어렵기 때문에 서로 교제를 피하는 관계이다.",
		score: 50,
	},
	mousefemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"뭔가 특별히 지속적인 관계가 맺어지지 않을 상대이다. 의사소통도 어렵기 때문에 서로 교제를 피하는 관계이다.",
		score: 50,
	},
	mousemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"상호간의 존중관계이다. 주도권 때문에 큰 어려움이나 다툼이 없을 상대이다.",
		score: 75,
	},
	mousemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"상호간의 존중관계이다. 주도권 때문에 큰 어려움이나 다툼이 없을 상대이다.",
		score: 75,
	},
	mousefemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"상호간의 존중관계이다. 주도권 때문에 큰 어려움이나 다툼이 없을 상대이다.",
		score: 75,
	},
	mousefemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"상호간의 존중관계이다. 주도권 때문에 큰 어려움이나 다툼이 없을 상대이다.",
		score: 75,
	},
	mousemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"대체로 행복해질 수 있는 관계이다. 개성의 충돌이 없어 상당한 정도로 관계를 맺을 만한 상대이다. 공동의 목적을 위해 협력한다.",
		score: 75,
	},
	mousemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"대체로 행복해질 수 있는 관계이다. 개성의 충돌이 없어 상당한 정도로 관계를 맺을 만한 상대이다. 공동의 목적을 위해 협력한다.",
		score: 75,
	},
	mousefemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"대체로 행복해질 수 있는 관계이다. 개성의 충돌이 없어 상당한 정도로 관계를 맺을 만한 상대이다. 공동의 목적을 위해 협력한다.",
		score: 75,
	},
	mousefemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=561476&cid=46670&categoryId=46670",
		notes:
			"대체로 행복해질 수 있는 관계이다. 개성의 충돌이 없어 상당한 정도로 관계를 맺을 만한 상대이다. 공동의 목적을 위해 협력한다.",
		score: 75,
	},
	oxmaleoxmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"공동의 관심사는 많으나 서로에 대한 이해가 깊지 못하다. 필요한 경우에만 협력할 상대이다.",
		score: 75,
	},
	oxmaleoxfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"공동의 관심사는 많으나 서로에 대한 이해가 깊지 못하다. 필요한 경우에만 협력할 상대이다.",
		score: 75,
	},
	oxfemaleoxfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"공동의 관심사는 많으나 서로에 대한 이해가 깊지 못하다. 필요한 경우에만 협력할 상대이다.",
		score: 75,
	},
	oxmaletigermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"개성이 충돌하면서 경쟁의식이 나타날 것이다. 서로에 대한 이해가 없기 때문에 적대감과 상호 불신이 깊어질 것이다. 서로의 차이점들을 해결할 방도가 없다.",
		score: 75,
	},
	oxmaletigerfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"개성이 충돌하면서 경쟁의식이 나타날 것이다. 서로에 대한 이해가 없기 때문에 적대감과 상호 불신이 깊어질 것이다. 서로의 차이점들을 해결할 방도가 없다.",
		score: 75,
	},
	oxfemaletigermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"개성이 충돌하면서 경쟁의식이 나타날 것이다. 서로에 대한 이해가 없기 때문에 적대감과 상호 불신이 깊어질 것이다. 서로의 차이점들을 해결할 방도가 없다.",
		score: 75,
	},
	oxfemaletigerfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"개성이 충돌하면서 경쟁의식이 나타날 것이다. 서로에 대한 이해가 없기 때문에 적대감과 상호 불신이 깊어질 것이다. 서로의 차이점들을 해결할 방도가 없다.",
		score: 75,
	},
	oxmalerabbitmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"특별한 충돌 없이 무난하게 지낼 만한 관계이다. 좋은 관계에서 팀을 이룰 수 있다.",
		score: 75,
	},
	oxmalerabbitfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"특별한 충돌 없이 무난하게 지낼 만한 관계이다. 좋은 관계에서 팀을 이룰 수 있다.",
		score: 75,
	},
	oxfemalerabbitmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"특별한 충돌 없이 무난하게 지낼 만한 관계이다. 좋은 관계에서 팀을 이룰 수 있다.",
		score: 75,
	},
	oxfemalerabbitfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"특별한 충돌 없이 무난하게 지낼 만한 관계이다. 좋은 관계에서 팀을 이룰 수 있다.",
		score: 75,
	},
	oxmaledragonmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"상호 존중하면서 함께 일할 수 있는 관계이다. 커다란 차이점이 없기 때문에 주도권 문제로 다툴 염려가 없다. 공동선을 위해 협력할 만한 상대이다.",
		score: 50,
	},
	oxmaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"상호 존중하면서 함께 일할 수 있는 관계이다. 커다란 차이점이 없기 때문에 주도권 문제로 다툴 염려가 없다. 공동선을 위해 협력할 만한 상대이다.",
		score: 50,
	},
	oxfemaledragonmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"상호 존중하면서 함께 일할 수 있는 관계이다. 커다란 차이점이 없기 때문에 주도권 문제로 다툴 염려가 없다. 공동선을 위해 협력할 만한 상대이다.",
		score: 50,
	},
	oxfemaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"상호 존중하면서 함께 일할 수 있는 관계이다. 커다란 차이점이 없기 때문에 주도권 문제로 다툴 염려가 없다. 공동선을 위해 협력할 만한 상대이다.",
		score: 50,
	},
	oxmalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 짝이다. 서로 동정하면서 아주 좋은 관계를 맺을 것이다. 지속적이고 성과 있는 유대관계가 가능한 상대이다.",
		score: 100,
	},
	oxmalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 짝이다. 서로 동정하면서 아주 좋은 관계를 맺을 것이다. 지속적이고 성과 있는 유대관계가 가능한 상대이다.",
		score: 100,
	},
	oxfemalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 짝이다. 서로 동정하면서 아주 좋은 관계를 맺을 것이다. 지속적이고 성과 있는 유대관계가 가능한 상대이다.",
		score: 100,
	},
	oxfemalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 짝이다. 서로 동정하면서 아주 좋은 관계를 맺을 것이다. 지속적이고 성과 있는 유대관계가 가능한 상대이다.",
		score: 100,
	},
	oxmalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"지속적인 관계가 불가능하다. 기껏해야 그저 그런 보통의 관계이다. 의사소통에서부터 장애가 따른다.",
		score: 50,
	},
	oxmalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"지속적인 관계가 불가능하다. 기껏해야 그저 그런 보통의 관계이다. 의사소통에서부터 장애가 따른다.",
		score: 50,
	},
	oxfemalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"지속적인 관계가 불가능하다. 기껏해야 그저 그런 보통의 관계이다. 의사소통에서부터 장애가 따른다.",
		score: 50,
	},
	oxfemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"지속적인 관계가 불가능하다. 기껏해야 그저 그런 보통의 관계이다. 의사소통에서부터 장애가 따른다.",
		score: 50,
	},
	oxmalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"기껏해야 참아 줄 만한 상대이다. 무관심과 충돌이 상당히 예상되는 관계이다. 잘해야 서로 냉정한 관계라도 유지될 수 있다. 협력을 기대하기 어려운 상대이다.",
		score: 50,
	},
	oxmalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"기껏해야 참아 줄 만한 상대이다. 무관심과 충돌이 상당히 예상되는 관계이다. 잘해야 서로 냉정한 관계라도 유지될 수 있다. 협력을 기대하기 어려운 상대이다.",
		score: 50,
	},
	oxfemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"기껏해야 참아 줄 만한 상대이다. 무관심과 충돌이 상당히 예상되는 관계이다. 잘해야 서로 냉정한 관계라도 유지될 수 있다. 협력을 기대하기 어려운 상대이다.",
		score: 50,
	},
	oxfemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"기껏해야 참아 줄 만한 상대이다. 무관심과 충돌이 상당히 예상되는 관계이다. 잘해야 서로 냉정한 관계라도 유지될 수 있다. 협력을 기대하기 어려운 상대이다.",
		score: 50,
	},
	oxmalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"상호 유보적인 태도를 취할 것이다. 노골적인 대립관계는 없을 테지만 타협을 위한 공통의 기반을 발견하기 어렵다.",
		score: 75,
	},
	oxmalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"상호 유보적인 태도를 취할 것이다. 노골적인 대립관계는 없을 테지만 타협을 위한 공통의 기반을 발견하기 어렵다.",
		score: 75,
	},
	oxfemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"상호 유보적인 태도를 취할 것이다. 노골적인 대립관계는 없을 테지만 타협을 위한 공통의 기반을 발견하기 어렵다.",
		score: 75,
	},
	oxfemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"상호 유보적인 태도를 취할 것이다. 노골적인 대립관계는 없을 테지만 타협을 위한 공통의 기반을 발견하기 어렵다.",
		score: 75,
	},
	oxmaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 짝이다. 아주 성공적인 결합이다. 서로의 의사소통에 아무런 어려움도 느끼지 못할 것이며 함께 사랑과 행복을 발견할 것이다.",
		score: 100,
	},
	oxmaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 짝이다. 아주 성공적인 결합이다. 서로의 의사소통에 아무런 어려움도 느끼지 못할 것이며 함께 사랑과 행복을 발견할 것이다.",
		score: 100,
	},
	oxfemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 짝이다. 아주 성공적인 결합이다. 서로의 의사소통에 아무런 어려움도 느끼지 못할 것이며 함께 사랑과 행복을 발견할 것이다.",
		score: 100,
	},
	oxfemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"아주 좋은 짝이다. 아주 성공적인 결합이다. 서로의 의사소통에 아무런 어려움도 느끼지 못할 것이며 함께 사랑과 행복을 발견할 것이다.",
		score: 100,
	},
	oxmaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"적대감이 약간은 생기며 공감대를 별로 발견하지 못할 것이다. 공통점이 거의 없기 때문에 좋은 관계를 기대하기 어렵다.",
		score: 50,
	},
	oxmaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"적대감이 약간은 생기며 공감대를 별로 발견하지 못할 것이다. 공통점이 거의 없기 때문에 좋은 관계를 기대하기 어렵다.",
		score: 50,
	},
	oxfemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"적대감이 약간은 생기며 공감대를 별로 발견하지 못할 것이다. 공통점이 거의 없기 때문에 좋은 관계를 기대하기 어렵다.",
		score: 50,
	},
	oxfemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"적대감이 약간은 생기며 공감대를 별로 발견하지 못할 것이다. 공통점이 거의 없기 때문에 좋은 관계를 기대하기 어렵다.",
		score: 50,
	},
	oxmalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"개성의 충돌이 없어서 그런 대로 함께 할 만한 상대이다. 그러나 강한 충돌도 강한 유대관계도 없는, 피차 냉정한 관계이다.",
		score: 75,
	},
	oxmalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"개성의 충돌이 없어서 그런 대로 함께 할 만한 상대이다. 그러나 강한 충돌도 강한 유대관계도 없는, 피차 냉정한 관계이다.",
		score: 75,
	},
	oxfemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"개성의 충돌이 없어서 그런 대로 함께 할 만한 상대이다. 그러나 강한 충돌도 강한 유대관계도 없는, 피차 냉정한 관계이다.",
		score: 75,
	},
	oxfemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557611&cid=46670&categoryId=46670",
		notes:
			"개성의 충돌이 없어서 그런 대로 함께 할 만한 상대이다. 그러나 강한 충돌도 강한 유대관계도 없는, 피차 냉정한 관계이다.",
		score: 75,
	},
	tigermaletigermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"그저 그런 관계까지만 가능하다. 사소한 적대감 때문에 서로에 대해 유보적 자세를 취하는 관계이다. 공동의 목표를 위해서만 함께 일할 수 있을 것이다.",
		score: 75,
	},
	tigermaletigerfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"그저 그런 관계까지만 가능하다. 사소한 적대감 때문에 서로에 대해 유보적 자세를 취하는 관계이다. 공동의 목표를 위해서만 함께 일할 수 있을 것이다.",
		score: 75,
	},
	tigerfemaletigerfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"그저 그런 관계까지만 가능하다. 사소한 적대감 때문에 서로에 대해 유보적 자세를 취하는 관계이다. 공동의 목표를 위해서만 함께 일할 수 있을 것이다.",
		score: 75,
	},
	tigermalerabbitmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"서로의 교제를 그런 대로 유지할 만한 상대이다. 큰 충돌은 없지만, 그렇다고 썩 우호적인 관계도 안 된다.",
		score: 75,
	},
	tigermalerabbitfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"서로의 교제를 그런 대로 유지할 만한 상대이다. 큰 충돌은 없지만, 그렇다고 썩 우호적인 관계도 안 된다.",
		score: 75,
	},
	tigerfemalerabbitmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"서로의 교제를 그런 대로 유지할 만한 상대이다. 큰 충돌은 없지만, 그렇다고 썩 우호적인 관계도 안 된다.",
		score: 75,
	},
	tigerfemalerabbitfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"서로의 교제를 그런 대로 유지할 만한 상대이다. 큰 충돌은 없지만, 그렇다고 썩 우호적인 관계도 안 된다.",
		score: 75,
	},
	tigermaledragonmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"상호존중과 상호 협력의 관계이다. 주도권 문제로 다소의 충돌은 예상되나 성격 차는 극복될 것이다. 대체로 둘 사이 만남은 성공적인 관계라 할 수 있다.",
		score: 75,
	},
	tigermaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"상호존중과 상호 협력의 관계이다. 주도권 문제로 다소의 충돌은 예상되나 성격 차는 극복될 것이다. 대체로 둘 사이 만남은 성공적인 관계라 할 수 있다.",
		score: 75,
	},
	tigerfemaledragonmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"상호존중과 상호 협력의 관계이다. 주도권 문제로 다소의 충돌은 예상되나 성격 차는 극복될 것이다. 대체로 둘 사이 만남은 성공적인 관계라 할 수 있다.",
		score: 75,
	},
	tigerfemaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"상호존중과 상호 협력의 관계이다. 주도권 문제로 다소의 충돌은 예상되나 성격 차는 극복될 것이다. 대체로 둘 사이 만남은 성공적인 관계라 할 수 있다.",
		score: 75,
	},
	tigermalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"서로 의심이 많을 관계이다. 서로 무관심하고 비우호적인 관계이다. 관계를 피하는 것이 좋다. 다툼과 적대 행위가 그치지 않을 것이다.",
		score: 50,
	},
	tigermalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"서로 의심이 많을 관계이다. 서로 무관심하고 비우호적인 관계이다. 관계를 피하는 것이 좋다. 다툼과 적대 행위가 그치지 않을 것이다.",
		score: 50,
	},
	tigerfemalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"서로 의심이 많을 관계이다. 서로 무관심하고 비우호적인 관계이다. 관계를 피하는 것이 좋다. 다툼과 적대 행위가 그치지 않을 것이다.",
		score: 50,
	},
	tigerfemalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"서로 의심이 많을 관계이다. 서로 무관심하고 비우호적인 관계이다. 관계를 피하는 것이 좋다. 다툼과 적대 행위가 그치지 않을 것이다.",
		score: 50,
	},
	tigermalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"결혼상대나 사업상대로 대단히 좋은 관계이다. 상호 신뢰와 상호이해 속에서 어려움이 없을 것이다. 매우 성공적인 결합이다.",
		score: 100,
	},
	tigermalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"결혼상대나 사업상대로 대단히 좋은 관계이다. 상호 신뢰와 상호이해 속에서 어려움이 없을 것이다. 매우 성공적인 결합이다.",
		score: 100,
	},
	tigerfemalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"결혼상대나 사업상대로 대단히 좋은 관계이다. 상호 신뢰와 상호이해 속에서 어려움이 없을 것이다. 매우 성공적인 결합이다.",
		score: 100,
	},
	tigerfemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"결혼상대나 사업상대로 대단히 좋은 관계이다. 상호 신뢰와 상호이해 속에서 어려움이 없을 것이다. 매우 성공적인 결합이다.",
		score: 100,
	},
	tigermalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"노골적인 대결관계나 경쟁관계는 없을 테지만 일정한 정도만 함께 일할 수 있는 관계이다. 깊은 유대감을 못 느낄 것이기 때문에 영구적인 관계가 되기는 어렵다.",
		score: 75,
	},
	tigermalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"노골적인 대결관계나 경쟁관계는 없을 테지만 일정한 정도만 함께 일할 수 있는 관계이다. 깊은 유대감을 못 느낄 것이기 때문에 영구적인 관계가 되기는 어렵다.",
		score: 75,
	},
	tigerfemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"노골적인 대결관계나 경쟁관계는 없을 테지만 일정한 정도만 함께 일할 수 있는 관계이다. 깊은 유대감을 못 느낄 것이기 때문에 영구적인 관계가 되기는 어렵다.",
		score: 75,
	},
	tigerfemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"노골적인 대결관계나 경쟁관계는 없을 테지만 일정한 정도만 함께 일할 수 있는 관계이다. 깊은 유대감을 못 느낄 것이기 때문에 영구적인 관계가 되기는 어렵다.",
		score: 75,
	},
	tigermalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"경쟁관계가 발생하고 성격충돌이 일어날 것이다. 공통의 관심사가 없다. 서로 마주하기도 싫어하기 때문에 성격차이를 해소시킬 수 없다.",
		score: 50,
	},
	tigermalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"경쟁관계가 발생하고 성격충돌이 일어날 것이다. 공통의 관심사가 없다. 서로 마주하기도 싫어하기 때문에 성격차이를 해소시킬 수 없다.",
		score: 50,
	},
	tigerfemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"경쟁관계가 발생하고 성격충돌이 일어날 것이다. 공통의 관심사가 없다. 서로 마주하기도 싫어하기 때문에 성격차이를 해소시킬 수 없다.",
		score: 50,
	},
	tigerfemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"경쟁관계가 발생하고 성격충돌이 일어날 것이다. 공통의 관심사가 없다. 서로 마주하기도 싫어하기 때문에 성격차이를 해소시킬 수 없다.",
		score: 50,
	},
	tigermaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"어느 정도는 서로 유보자세를 취하며 의사소통에는 문제가 있다. 서로 성질을 돋우기 때문에 결국 무관심이 상책이며, 잘 해야 냉정한 관계이다.",
		score: 50,
	},
	tigermaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"어느 정도는 서로 유보자세를 취하며 의사소통에는 문제가 있다. 서로 성질을 돋우기 때문에 결국 무관심이 상책이며, 잘 해야 냉정한 관계이다.",
		score: 50,
	},
	tigerfemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"어느 정도는 서로 유보자세를 취하며 의사소통에는 문제가 있다. 서로 성질을 돋우기 때문에 결국 무관심이 상책이며, 잘 해야 냉정한 관계이다.",
		score: 50,
	},
	tigerfemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"어느 정도는 서로 유보자세를 취하며 의사소통에는 문제가 있다. 서로 성질을 돋우기 때문에 결국 무관심이 상책이며, 잘 해야 냉정한 관계이다.",
		score: 50,
	},
	tigermaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"이상적인 결합이다. 서로간의 의사 소통에 아무런 어려움이 없다. 함께 커다란 성공과 번영을 이룰 수 있는 관계이다.",
		score: 100,
	},
	tigermaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"이상적인 결합이다. 서로간의 의사 소통에 아무런 어려움이 없다. 함께 커다란 성공과 번영을 이룰 수 있는 관계이다.",
		score: 100,
	},
	tigerfemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"이상적인 결합이다. 서로간의 의사 소통에 아무런 어려움이 없다. 함께 커다란 성공과 번영을 이룰 수 있는 관계이다.",
		score: 100,
	},
	tigerfemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"이상적인 결합이다. 서로간의 의사 소통에 아무런 어려움이 없다. 함께 커다란 성공과 번영을 이룰 수 있는 관계이다.",
		score: 100,
	},
	tigermalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"안전하고 성공적인 상대이다. 함께 하면 사랑과 행복이 있을 것이다. 애정과 사업에 있어서 매우 협력적인 관계이다.",
		score: 100,
	},
	tigermalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"안전하고 성공적인 상대이다. 함께 하면 사랑과 행복이 있을 것이다. 애정과 사업에 있어서 매우 협력적인 관계이다.",
		score: 100,
	},
	tigerfemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"안전하고 성공적인 상대이다. 함께 하면 사랑과 행복이 있을 것이다. 애정과 사업에 있어서 매우 협력적인 관계이다.",
		score: 100,
	},
	tigerfemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557399&cid=46670&categoryId=46670",
		notes:
			"안전하고 성공적인 상대이다. 함께 하면 사랑과 행복이 있을 것이다. 애정과 사업에 있어서 매우 협력적인 관계이다.",
		score: 100,
	},
	rabbitmalerabbitmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"상호 협조 속에서 주도권 투쟁도 없다. 의사소통에 장애가 없어서 평화적인 협력이 가능하다.",
		score: 75,
	},
	rabbitmalerabbitfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"상호 협조 속에서 주도권 투쟁도 없다. 의사소통에 장애가 없어서 평화적인 협력이 가능하다.",
		score: 75,
	},
	rabbitfemalerabbitfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"상호 협조 속에서 주도권 투쟁도 없다. 의사소통에 장애가 없어서 평화적인 협력이 가능하다.",
		score: 75,
	},
	rabbitmaledragonmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"공통의 이해 기반이 있다. 성격 차이로 인한 약간의 충돌이 예상되니 그 해소를 위해 함께 노력해야 한다.",
		score: 50,
	},
	rabbitmaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"공통의 이해 기반이 있다. 성격 차이로 인한 약간의 충돌이 예상되니 그 해소를 위해 함께 노력해야 한다.",
		score: 50,
	},
	rabbitfemaledragonmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"공통의 이해 기반이 있다. 성격 차이로 인한 약간의 충돌이 예상되니 그 해소를 위해 함께 노력해야 한다.",
		score: 50,
	},
	rabbitfemaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"공통의 이해 기반이 있다. 성격 차이로 인한 약간의 충돌이 예상되니 그 해소를 위해 함께 노력해야 한다.",
		score: 50,
	},
	rabbitmalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"협조관계가 가능하다. 공동의 목적을 위해 서로 참겠지만 상호간에 깊은 매력이나 강한 매력은 느끼지 못한다.",
		score: 75,
	},
	rabbitmalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"협조관계가 가능하다. 공동의 목적을 위해 서로 참겠지만 상호간에 깊은 매력이나 강한 매력은 느끼지 못한다.",
		score: 75,
	},
	rabbitfemalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"협조관계가 가능하다. 공동의 목적을 위해 서로 참겠지만 상호간에 깊은 매력이나 강한 매력은 느끼지 못한다.",
		score: 75,
	},
	rabbitfemalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"협조관계가 가능하다. 공동의 목적을 위해 서로 참겠지만 상호간에 깊은 매력이나 강한 매력은 느끼지 못한다.",
		score: 75,
	},
	rabbitmalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"적대감 속에서의 강한 충돌관계에 있기 때문에 서로의 유대가 불가능하다. 공통점이라 할만한 것이 거의 없어서 비협조적인 관계로 끝난다.",
		score: 50,
	},
	rabbitmalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"적대감 속에서의 강한 충돌관계에 있기 때문에 서로의 유대가 불가능하다. 공통점이라 할만한 것이 거의 없어서 비협조적인 관계로 끝난다.",
		score: 50,
	},
	rabbitfemalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"적대감 속에서의 강한 충돌관계에 있기 때문에 서로의 유대가 불가능하다. 공통점이라 할만한 것이 거의 없어서 비협조적인 관계로 끝난다.",
		score: 50,
	},
	rabbitfemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"적대감 속에서의 강한 충돌관계에 있기 때문에 서로의 유대가 불가능하다. 공통점이라 할만한 것이 거의 없어서 비협조적인 관계로 끝난다.",
		score: 50,
	},
	rabbitmalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"가장 좋은 짝들 가운데 하나이다. 상호 사랑과 상호 이해 속에서 공통점이 많다. 둘의 결합은 성공과 번영을 약속한다.",
		score: 100,
	},
	rabbitmalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"가장 좋은 짝들 가운데 하나이다. 상호 사랑과 상호 이해 속에서 공통점이 많다. 둘의 결합은 성공과 번영을 약속한다.",
		score: 100,
	},
	rabbitfemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"가장 좋은 짝들 가운데 하나이다. 상호 사랑과 상호 이해 속에서 공통점이 많다. 둘의 결합은 성공과 번영을 약속한다.",
		score: 100,
	},
	rabbitfemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"가장 좋은 짝들 가운데 하나이다. 상호 사랑과 상호 이해 속에서 공통점이 많다. 둘의 결합은 성공과 번영을 약속한다.",
		score: 100,
	},
	rabbitmalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"약간의 성격 차와 경쟁의식이 존재한다. 적당한 관계 속에서 어느 정도까지는 서로 참겠지만 완전한 신뢰관계로까지는 발전되지 못한다.",
		score: 50,
	},
	rabbitmalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"약간의 성격 차와 경쟁의식이 존재한다. 적당한 관계 속에서 어느 정도까지는 서로 참겠지만 완전한 신뢰관계로까지는 발전되지 못한다.",
		score: 50,
	},
	rabbitfemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"약간의 성격 차와 경쟁의식이 존재한다. 적당한 관계 속에서 어느 정도까지는 서로 참겠지만 완전한 신뢰관계로까지는 발전되지 못한다.",
		score: 50,
	},
	rabbitfemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"약간의 성격 차와 경쟁의식이 존재한다. 적당한 관계 속에서 어느 정도까지는 서로 참겠지만 완전한 신뢰관계로까지는 발전되지 못한다.",
		score: 50,
	},
	rabbitmaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"충돌과 견해차가 많다. 날씨 같은 사소한 문제로도 다툼이 생긴다. 주도권 문제로 갈등이 커서 관계를 맺기에 아주 곤란한 상대이다.",
		score: 25,
	},
	rabbitmaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"충돌과 견해차가 많다. 날씨 같은 사소한 문제로도 다툼이 생긴다. 주도권 문제로 갈등이 커서 관계를 맺기에 아주 곤란한 상대이다.",
		score: 25,
	},
	rabbitfemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"충돌과 견해차가 많다. 날씨 같은 사소한 문제로도 다툼이 생긴다. 주도권 문제로 갈등이 커서 관계를 맺기에 아주 곤란한 상대이다.",
		score: 25,
	},
	rabbitfemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"충돌과 견해차가 많다. 날씨 같은 사소한 문제로도 다툼이 생긴다. 주도권 문제로 갈등이 커서 관계를 맺기에 아주 곤란한 상대이다.",
		score: 25,
	},
	rabbitmaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"아주 바람직하고 성과가 클 관계이다. 서로간의 깊은 신뢰와 이해 속에서 번영과 행복을 찾는다.",
		score: 100,
	},
	rabbitmaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"아주 바람직하고 성과가 클 관계이다. 서로간의 깊은 신뢰와 이해 속에서 번영과 행복을 찾는다.",
		score: 100,
	},
	rabbitfemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"아주 바람직하고 성과가 클 관계이다. 서로간의 깊은 신뢰와 이해 속에서 번영과 행복을 찾는다.",
		score: 100,
	},
	rabbitfemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"아주 바람직하고 성과가 클 관계이다. 서로간의 깊은 신뢰와 이해 속에서 번영과 행복을 찾는다.",
		score: 100,
	},
	rabbitmalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"상당히 좋은 관계이다. 충심으로 서로를 이해하며 서로의 느낌에 아주 큰 공감을 느낄 수 있다.",
		score: 100,
	},
	rabbitmalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"상당히 좋은 관계이다. 충심으로 서로를 이해하며 서로의 느낌에 아주 큰 공감을 느낄 수 있다.",
		score: 100,
	},
	rabbitfemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"상당히 좋은 관계이다. 충심으로 서로를 이해하며 서로의 느낌에 아주 큰 공감을 느낄 수 있다.",
		score: 100,
	},
	rabbitfemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=530713&cid=46670&categoryId=46670",
		notes:
			"상당히 좋은 관계이다. 충심으로 서로를 이해하며 서로의 느낌에 아주 큰 공감을 느낄 수 있다.",
		score: 100,
	},
	dragonmaledragonmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"큰 충돌은 없다. 마음이 맞는 좋은 관계이다. 적당한 한도까지는 문제가 없다.",
		score: 50,
	},
	dragonmaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"큰 충돌은 없다. 마음이 맞는 좋은 관계이다. 적당한 한도까지는 문제가 없다.",
		score: 50,
	},
	dragonfemaledragonfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"큰 충돌은 없다. 마음이 맞는 좋은 관계이다. 적당한 한도까지는 문제가 없다.",
		score: 50,
	},
	dragonmalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"서로가 잘 협동하고 이해할 것이다. 연애와 사업상 성공적인 관계이다. 서로 만족한다.",
		score: 75,
	},
	dragonmalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"서로가 잘 협동하고 이해할 것이다. 연애와 사업상 성공적인 관계이다. 서로 만족한다.",
		score: 75,
	},
	dragonfemalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"서로가 잘 협동하고 이해할 것이다. 연애와 사업상 성공적인 관계이다. 서로 만족한다.",
		score: 75,
	},
	dragonfemalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"서로가 잘 협동하고 이해할 것이다. 연애와 사업상 성공적인 관계이다. 서로 만족한다.",
		score: 75,
	},
	dragonmalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"알맞은 관계이다. 서로에 대해 존경심을 가진다. 주도권을 놓고 약간의 다툼이 있다. 불만을 해결하기 위해 노력할 것이다.",
		score: 75,
	},
	dragonmalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"알맞은 관계이다. 서로에 대해 존경심을 가진다. 주도권을 놓고 약간의 다툼이 있다. 불만을 해결하기 위해 노력할 것이다.",
		score: 75,
	},
	dragonfemalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"알맞은 관계이다. 서로에 대해 존경심을 가진다. 주도권을 놓고 약간의 다툼이 있다. 불만을 해결하기 위해 노력할 것이다.",
		score: 75,
	},
	dragonfemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"알맞은 관계이다. 서로에 대해 존경심을 가진다. 주도권을 놓고 약간의 다툼이 있다. 불만을 해결하기 위해 노력할 것이다.",
		score: 75,
	},
	dragonmalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"연애와 사업에서 괜찮지만 냉담한 관계이다. 비슷한 관심에 관해서는 적당히 마음이 맞는다.",
		score: 75,
	},
	dragonmalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"연애와 사업에서 괜찮지만 냉담한 관계이다. 비슷한 관심에 관해서는 적당히 마음이 맞는다.",
		score: 75,
	},
	dragonfemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"연애와 사업에서 괜찮지만 냉담한 관계이다. 비슷한 관심에 관해서는 적당히 마음이 맞는다.",
		score: 75,
	},
	dragonfemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"연애와 사업에서 괜찮지만 냉담한 관계이다. 비슷한 관심에 관해서는 적당히 마음이 맞는다.",
		score: 75,
	},
	dragonmalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"결혼과 동업에서 뛰어난 조화를 이룬다. 서로에 대해 깊은 애정과 상호 이해를 가지고 있다. 서로가 성공하고 행복하다.",
		score: 100,
	},
	dragonmalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"결혼과 동업에서 뛰어난 조화를 이룬다. 서로에 대해 깊은 애정과 상호 이해를 가지고 있다. 서로가 성공하고 행복하다.",
		score: 100,
	},
	dragonfemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"결혼과 동업에서 뛰어난 조화를 이룬다. 서로에 대해 깊은 애정과 상호 이해를 가지고 있다. 서로가 성공하고 행복하다.",
		score: 100,
	},
	dragonfemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"결혼과 동업에서 뛰어난 조화를 이룬다. 서로에 대해 깊은 애정과 상호 이해를 가지고 있다. 서로가 성공하고 행복하다.",
		score: 100,
	},
	dragonmaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"성공적이며 매우 잘 어울리는 관계이다. 행복과 번영을 찾을 것이다. 깊고 오래 지속되는 관계이다.",
		score: 100,
	},
	dragonmaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"성공적이며 매우 잘 어울리는 관계이다. 행복과 번영을 찾을 것이다. 깊고 오래 지속되는 관계이다.",
		score: 100,
	},
	dragonfemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"성공적이며 매우 잘 어울리는 관계이다. 행복과 번영을 찾을 것이다. 깊고 오래 지속되는 관계이다.",
		score: 100,
	},
	dragonfemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"성공적이며 매우 잘 어울리는 관계이다. 행복과 번영을 찾을 것이다. 깊고 오래 지속되는 관계이다.",
		score: 100,
	},
	dragonmaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"적대감과 큰 충돌이 있다. 서로를 믿거나 이해할 수 없다. 차이를 극복할 수 없다. 가장 안 어울린다.",
		score: 25,
	},
	dragonmaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"적대감과 큰 충돌이 있다. 서로를 믿거나 이해할 수 없다. 차이를 극복할 수 없다. 가장 안 어울린다.",
		score: 25,
	},
	dragonfemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"적대감과 큰 충돌이 있다. 서로를 믿거나 이해할 수 없다. 차이를 극복할 수 없다. 가장 안 어울린다.",
		score: 25,
	},
	dragonfemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"적대감과 큰 충돌이 있다. 서로를 믿거나 이해할 수 없다. 차이를 극복할 수 없다. 가장 안 어울린다.",
		score: 25,
	},
	dragonmalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"마음에서 우러나 실행하는 관계이다. 주도권을 잡기 위한 큰 불화나 심각한 싸움은 없다. 공동의 목표를 위해 서로 도와나갈 수 있다.",
		score: 50,
	},
	dragonmalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"마음에서 우러나 실행하는 관계이다. 주도권을 잡기 위한 큰 불화나 심각한 싸움은 없다. 공동의 목표를 위해 서로 도와나갈 수 있다.",
		score: 50,
	},
	dragonfemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"마음에서 우러나 실행하는 관계이다. 주도권을 잡기 위한 큰 불화나 심각한 싸움은 없다. 공동의 목표를 위해 서로 도와나갈 수 있다.",
		score: 50,
	},
	dragonfemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=569234&cid=46670&categoryId=46670",
		notes:
			"마음에서 우러나 실행하는 관계이다. 주도권을 잡기 위한 큰 불화나 심각한 싸움은 없다. 공동의 목표를 위해 서로 도와나갈 수 있다.",
		score: 50,
	},
	snakemalesnakemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로의 성격을 잘 이해하므로 공동의 관심분야가 있게 되면 강한 유대를 형성하게 된다. 아무런 다툼이 없다.",
		score: 75,
	},
	snakemalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로의 성격을 잘 이해하므로 공동의 관심분야가 있게 되면 강한 유대를 형성하게 된다. 아무런 다툼이 없다.",
		score: 75,
	},
	snakefemalesnakefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로의 성격을 잘 이해하므로 공동의 관심분야가 있게 되면 강한 유대를 형성하게 된다. 아무런 다툼이 없다.",
		score: 75,
	},
	snakemalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"약간의 갈등을 갖고 대결하는 사이이다. 직접 마주하는 데 어려움이 있다. 같이 지내기 어려우며 냉정하고 먼 관계를 갖게 된다.",
		score: 75,
	},
	snakemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"약간의 갈등을 갖고 대결하는 사이이다. 직접 마주하는 데 어려움이 있다. 같이 지내기 어려우며 냉정하고 먼 관계를 갖게 된다.",
		score: 75,
	},
	snakefemalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"약간의 갈등을 갖고 대결하는 사이이다. 직접 마주하는 데 어려움이 있다. 같이 지내기 어려우며 냉정하고 먼 관계를 갖게 된다.",
		score: 75,
	},
	snakefemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"약간의 갈등을 갖고 대결하는 사이이다. 직접 마주하는 데 어려움이 있다. 같이 지내기 어려우며 냉정하고 먼 관계를 갖게 된다.",
		score: 75,
	},
	snakemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"따뜻하고 온화한 관계이다. 상당한 정도까지 조화를 이룬다. 잠재된 갈등관계가 없으므로 필요할 때에 협력할 수 있다.",
		score: 75,
	},
	snakemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"따뜻하고 온화한 관계이다. 상당한 정도까지 조화를 이룬다. 잠재된 갈등관계가 없으므로 필요할 때에 협력할 수 있다.",
		score: 75,
	},
	snakefemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"따뜻하고 온화한 관계이다. 상당한 정도까지 조화를 이룬다. 잠재된 갈등관계가 없으므로 필요할 때에 협력할 수 있다.",
		score: 75,
	},
	snakefemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"따뜻하고 온화한 관계이다. 상당한 정도까지 조화를 이룬다. 잠재된 갈등관계가 없으므로 필요할 때에 협력할 수 있다.",
		score: 75,
	},
	snakemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"잘 조화되지 않은 관계이다. 신뢰와 선의가 부족할 때에는 다툼과 갈등이 있게 된다. 둘이 협력한다면 틀림없이 많은 것을 얻을 수 있다.",
		score: 50,
	},
	snakemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"잘 조화되지 않은 관계이다. 신뢰와 선의가 부족할 때에는 다툼과 갈등이 있게 된다. 둘이 협력한다면 틀림없이 많은 것을 얻을 수 있다.",
		score: 50,
	},
	snakefemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"잘 조화되지 않은 관계이다. 신뢰와 선의가 부족할 때에는 다툼과 갈등이 있게 된다. 둘이 협력한다면 틀림없이 많은 것을 얻을 수 있다.",
		score: 50,
	},
	snakefemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"잘 조화되지 않은 관계이다. 신뢰와 선의가 부족할 때에는 다툼과 갈등이 있게 된다. 둘이 협력한다면 틀림없이 많은 것을 얻을 수 있다.",
		score: 50,
	},
	snakemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"결혼을 하거나 사업을 하는 데 있어 가장 좋은 짝이다. 서로를 믿을 수 있고 매우 잘 이해한다. 매우 조화롭고 성공적인 쌍이 될 수 있다.",
		score: 100,
	},
	snakemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"결혼을 하거나 사업을 하는 데 있어 가장 좋은 짝이다. 서로를 믿을 수 있고 매우 잘 이해한다. 매우 조화롭고 성공적인 쌍이 될 수 있다.",
		score: 100,
	},
	snakefemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"결혼을 하거나 사업을 하는 데 있어 가장 좋은 짝이다. 서로를 믿을 수 있고 매우 잘 이해한다. 매우 조화롭고 성공적인 쌍이 될 수 있다.",
		score: 100,
	},
	snakefemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"결혼을 하거나 사업을 하는 데 있어 가장 좋은 짝이다. 서로를 믿을 수 있고 매우 잘 이해한다. 매우 조화롭고 성공적인 쌍이 될 수 있다.",
		score: 100,
	},
	snakemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로 존경하고 공동의 관심이 많은 관계이다. 커다란 갈등은 없고 상당한 정도까지 조화된다. 서로 상냥하게 협력할 수 있다.",
		score: 50,
	},
	snakemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로 존경하고 공동의 관심이 많은 관계이다. 커다란 갈등은 없고 상당한 정도까지 조화된다. 서로 상냥하게 협력할 수 있다.",
		score: 50,
	},
	snakefemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로 존경하고 공동의 관심이 많은 관계이다. 커다란 갈등은 없고 상당한 정도까지 조화된다. 서로 상냥하게 협력할 수 있다.",
		score: 50,
	},
	snakefemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로 존경하고 공동의 관심이 많은 관계이다. 커다란 갈등은 없고 상당한 정도까지 조화된다. 서로 상냥하게 협력할 수 있다.",
		score: 50,
	},
	snakemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로 관계를 맺거나 이해할 수 없는 관계이다. 깊고 지속적인 갈등을 가지고 있다. 의견교환의 단절을 극복할 수 없고 서로의 차이를 해결할 수 없다.",
		score: 50,
	},
	snakemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로 관계를 맺거나 이해할 수 없는 관계이다. 깊고 지속적인 갈등을 가지고 있다. 의견교환의 단절을 극복할 수 없고 서로의 차이를 해결할 수 없다.",
		score: 50,
	},
	snakefemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로 관계를 맺거나 이해할 수 없는 관계이다. 깊고 지속적인 갈등을 가지고 있다. 의견교환의 단절을 극복할 수 없고 서로의 차이를 해결할 수 없다.",
		score: 50,
	},
	snakefemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=557338&cid=46670&categoryId=46670",
		notes:
			"서로 관계를 맺거나 이해할 수 없는 관계이다. 깊고 지속적인 갈등을 가지고 있다. 의견교환의 단절을 극복할 수 없고 서로의 차이를 해결할 수 없다.",
		score: 50,
	},
	horsemalehorsemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes: "서로 굽히지 않으면 갈등이 초래될 사이이다.",
		score: 50,
	},
	horsemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes: "서로 굽히지 않으면 갈등이 초래될 사이이다.",
		score: 50,
	},
	horsefemalehorsefemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes: "서로 굽히지 않으면 갈등이 초래될 사이이다.",
		score: 50,
	},
	horsemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"매우 잘 맞고 성공적인 사이이다. 서로에게 강한 친밀감과 매력을 느낀다. 사랑과 사업에서 서로 이익이 되는 관계가 된다.",
		score: 100,
	},
	horsemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"매우 잘 맞고 성공적인 사이이다. 서로에게 강한 친밀감과 매력을 느낀다. 사랑과 사업에서 서로 이익이 되는 관계가 된다.",
		score: 100,
	},
	horsefemalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"매우 잘 맞고 성공적인 사이이다. 서로에게 강한 친밀감과 매력을 느낀다. 사랑과 사업에서 서로 이익이 되는 관계가 된다.",
		score: 100,
	},
	horsefemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"매우 잘 맞고 성공적인 사이이다. 서로에게 강한 친밀감과 매력을 느낀다. 사랑과 사업에서 서로 이익이 되는 관계가 된다.",
		score: 100,
	},
	horsemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"공통의 목적을 위하여 어느 정도까지는 협력할 수 있다. 비슷한 관심을 많이 가지고 있지만 강한 유대감이나 이해는 갖지 못한다.",
		score: 75,
	},
	horsemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"공통의 목적을 위하여 어느 정도까지는 협력할 수 있다. 비슷한 관심을 많이 가지고 있지만 강한 유대감이나 이해는 갖지 못한다.",
		score: 75,
	},
	horsefemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"공통의 목적을 위하여 어느 정도까지는 협력할 수 있다. 비슷한 관심을 많이 가지고 있지만 강한 유대감이나 이해는 갖지 못한다.",
		score: 75,
	},
	horsefemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"공통의 목적을 위하여 어느 정도까지는 협력할 수 있다. 비슷한 관심을 많이 가지고 있지만 강한 유대감이나 이해는 갖지 못한다.",
		score: 75,
	},
	horsemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"약간의 의사소통상의 장애가 있고 성격적으로 부딪친다. 차이점을 해소할 수는 있으나 친밀한 사이가 되기에는 냉정한 사이이다.",
		score: 75,
	},
	horsemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"약간의 의사소통상의 장애가 있고 성격적으로 부딪친다. 차이점을 해소할 수는 있으나 친밀한 사이가 되기에는 냉정한 사이이다.",
		score: 75,
	},
	horsefemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"약간의 의사소통상의 장애가 있고 성격적으로 부딪친다. 차이점을 해소할 수는 있으나 친밀한 사이가 되기에는 냉정한 사이이다.",
		score: 75,
	},
	horsefemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"약간의 의사소통상의 장애가 있고 성격적으로 부딪친다. 차이점을 해소할 수는 있으나 친밀한 사이가 되기에는 냉정한 사이이다.",
		score: 75,
	},
	horsemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"매우 행복하고 잘 맞는 짝이다. 사랑하고 같이 일을 하면 성공할 수 있고 성과가 많게 된다. 서로에 대해서 깊은 이해와 애정을 가지고 있다.",
		score: 100,
	},
	horsemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"매우 행복하고 잘 맞는 짝이다. 사랑하고 같이 일을 하면 성공할 수 있고 성과가 많게 된다. 서로에 대해서 깊은 이해와 애정을 가지고 있다.",
		score: 100,
	},
	horsefemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"매우 행복하고 잘 맞는 짝이다. 사랑하고 같이 일을 하면 성공할 수 있고 성과가 많게 된다. 서로에 대해서 깊은 이해와 애정을 가지고 있다.",
		score: 100,
	},
	horsefemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"매우 행복하고 잘 맞는 짝이다. 사랑하고 같이 일을 하면 성공할 수 있고 성과가 많게 된다. 서로에 대해서 깊은 이해와 애정을 가지고 있다.",
		score: 100,
	},
	horsemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"잘 맞는 관계이다. 공통의 관심사를 많이 가지고 있다. 특별한 매력을 느끼지도 않지만 강한 다툼도 없다.",
		score: 75,
	},
	horsemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"잘 맞는 관계이다. 공통의 관심사를 많이 가지고 있다. 특별한 매력을 느끼지도 않지만 강한 다툼도 없다.",
		score: 75,
	},
	horsefemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"잘 맞는 관계이다. 공통의 관심사를 많이 가지고 있다. 특별한 매력을 느끼지도 않지만 강한 다툼도 없다.",
		score: 75,
	},
	horsefemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=545432&cid=46670&categoryId=46670",
		notes:
			"잘 맞는 관계이다. 공통의 관심사를 많이 가지고 있다. 특별한 매력을 느끼지도 않지만 강한 다툼도 없다.",
		score: 75,
	},
	lambmalelambmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"서로 경쟁하지 않는 관계이다. 함께 일할 수 있다. 관심분야가 같고 목표가 동일하다. 연애와 사업분야에서 바람직한 관계이다.",
		score: 75,
	},
	lambmalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"서로 경쟁하지 않는 관계이다. 함께 일할 수 있다. 관심분야가 같고 목표가 동일하다. 연애와 사업분야에서 바람직한 관계이다.",
		score: 75,
	},
	lambfemalelambfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"서로 경쟁하지 않는 관계이다. 함께 일할 수 있다. 관심분야가 같고 목표가 동일하다. 연애와 사업분야에서 바람직한 관계이다.",
		score: 75,
	},
	lambmalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"서로 깊이 이해하지 못하고 특별한 요구도 하지 않는다. 공통점이 거의 없고 서로 매력을 느끼지 못한다.",
		score: 75,
	},
	lambmalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"서로 깊이 이해하지 못하고 특별한 요구도 하지 않는다. 공통점이 거의 없고 서로 매력을 느끼지 못한다.",
		score: 75,
	},
	lambfemalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"서로 깊이 이해하지 못하고 특별한 요구도 하지 않는다. 공통점이 거의 없고 서로 매력을 느끼지 못한다.",
		score: 75,
	},
	lambfemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"서로 깊이 이해하지 못하고 특별한 요구도 하지 않는다. 공통점이 거의 없고 서로 매력을 느끼지 못한다.",
		score: 75,
	},
	lambmaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"의사소통이 잘 안 되고 상호 침묵한다. 보통관계이다. 어느 정도 서로 참는 것이 최선이다.",
		score: 75,
	},
	lambmaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"의사소통이 잘 안 되고 상호 침묵한다. 보통관계이다. 어느 정도 서로 참는 것이 최선이다.",
		score: 75,
	},
	lambfemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"의사소통이 잘 안 되고 상호 침묵한다. 보통관계이다. 어느 정도 서로 참는 것이 최선이다.",
		score: 75,
	},
	lambfemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"의사소통이 잘 안 되고 상호 침묵한다. 보통관계이다. 어느 정도 서로 참는 것이 최선이다.",
		score: 75,
	},
	lambmaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"공통점이 거의 없으며 잘 어울리지 않는다. 서로 관계를 맺으려는 욕구를 느끼지 못한다. 조화롭지 못하다.",
		score: 50,
	},
	lambmaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"공통점이 거의 없으며 잘 어울리지 않는다. 서로 관계를 맺으려는 욕구를 느끼지 못한다. 조화롭지 못하다.",
		score: 50,
	},
	lambfemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"공통점이 거의 없으며 잘 어울리지 않는다. 서로 관계를 맺으려는 욕구를 느끼지 못한다. 조화롭지 못하다.",
		score: 50,
	},
	lambfemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"공통점이 거의 없으며 잘 어울리지 않는다. 서로 관계를 맺으려는 욕구를 느끼지 못한다. 조화롭지 못하다.",
		score: 50,
	},
	lambmalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"행복하고 조화로운 상대자이다. 서로 잘 이해한다. 함께 조화롭게 일하고 서로 의지한다.",
		score: 100,
	},
	lambmalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"행복하고 조화로운 상대자이다. 서로 잘 이해한다. 함께 조화롭게 일하고 서로 의지한다.",
		score: 100,
	},
	lambfemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"행복하고 조화로운 상대자이다. 서로 잘 이해한다. 함께 조화롭게 일하고 서로 의지한다.",
		score: 100,
	},
	lambfemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=580398&cid=46670&categoryId=46670",
		notes:
			"행복하고 조화로운 상대자이다. 서로 잘 이해한다. 함께 조화롭게 일하고 서로 의지한다.",
		score: 100,
	},
	monkeymalemonkeymale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"같은 구성원으로써 함께 성공적으로 일 할 수 있다. 관심의 영역이 같다. 사업과 사랑에서 우호적인 관계이다. 강한 경쟁의식이 없다.",
		score: 75,
	},
	monkeymalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"같은 구성원으로써 함께 성공적으로 일 할 수 있다. 관심의 영역이 같다. 사업과 사랑에서 우호적인 관계이다. 강한 경쟁의식이 없다.",
		score: 75,
	},
	monkeyfemalemonkeyfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"같은 구성원으로써 함께 성공적으로 일 할 수 있다. 관심의 영역이 같다. 사업과 사랑에서 우호적인 관계이다. 강한 경쟁의식이 없다.",
		score: 75,
	},
	monkeymaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"일정한 정도까지만 의사소통이 가능하다. 서로의 협동이 요구된다면 서로서로 참아야 한다. 차가운 관계이다.",
		score: 75,
	},
	monkeymaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"일정한 정도까지만 의사소통이 가능하다. 서로의 협동이 요구된다면 서로서로 참아야 한다. 차가운 관계이다.",
		score: 75,
	},
	monkeyfemaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"일정한 정도까지만 의사소통이 가능하다. 서로의 협동이 요구된다면 서로서로 참아야 한다. 차가운 관계이다.",
		score: 75,
	},
	monkeyfemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"일정한 정도까지만 의사소통이 가능하다. 서로의 협동이 요구된다면 서로서로 참아야 한다. 차가운 관계이다.",
		score: 75,
	},
	monkeymaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes: "서로 존경하고 서로에 대하여 우호적인 감정을 지닌다. 적대감이 없다.",
		score: 75,
	},
	monkeymaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes: "서로 존경하고 서로에 대하여 우호적인 감정을 지닌다. 적대감이 없다.",
		score: 75,
	},
	monkeyfemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes: "서로 존경하고 서로에 대하여 우호적인 감정을 지닌다. 적대감이 없다.",
		score: 75,
	},
	monkeyfemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes: "서로 존경하고 서로에 대하여 우호적인 감정을 지닌다. 적대감이 없다.",
		score: 75,
	},
	monkeymalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"공통의 관심영역이 있다. 서로 관계에 있어 무리가 없다. 사랑과 사업에 있어 아주 좋은 관계이다. 큰 부딪힘이 없다.",
		score: 50,
	},
	monkeymalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"공통의 관심영역이 있다. 서로 관계에 있어 무리가 없다. 사랑과 사업에 있어 아주 좋은 관계이다. 큰 부딪힘이 없다.",
		score: 50,
	},
	monkeyfemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"공통의 관심영역이 있다. 서로 관계에 있어 무리가 없다. 사랑과 사업에 있어 아주 좋은 관계이다. 큰 부딪힘이 없다.",
		score: 50,
	},
	monkeyfemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=537990&cid=46670&categoryId=46670",
		notes:
			"공통의 관심영역이 있다. 서로 관계에 있어 무리가 없다. 사랑과 사업에 있어 아주 좋은 관계이다. 큰 부딪힘이 없다.",
		score: 50,
	},
	roostermaleroostermale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"서로 뜻이 충돌하고 서로의 접촉에서 우를 점하려 싸운다. 이해의 영역이 거의 없다. 기껏해야 공손한 관계이다.",
		score: 50,
	},
	roostermaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"서로 뜻이 충돌하고 서로의 접촉에서 우를 점하려 싸운다. 이해의 영역이 거의 없다. 기껏해야 공손한 관계이다.",
		score: 50,
	},
	roosterfemaleroosterfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"서로 뜻이 충돌하고 서로의 접촉에서 우를 점하려 싸운다. 이해의 영역이 거의 없다. 기껏해야 공손한 관계이다.",
		score: 50,
	},
	roostermaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"절제하는 차가운 관계이다. 서로의 관계에 있어 문제들이 많으며 기본적으로 서로에 대해 적대시한다. 기껏해야 단지 서로 참는 것이다.",
		score: 50,
	},
	roostermaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"절제하는 차가운 관계이다. 서로의 관계에 있어 문제들이 많으며 기본적으로 서로에 대해 적대시한다. 기껏해야 단지 서로 참는 것이다.",
		score: 50,
	},
	roosterfemaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"절제하는 차가운 관계이다. 서로의 관계에 있어 문제들이 많으며 기본적으로 서로에 대해 적대시한다. 기껏해야 단지 서로 참는 것이다.",
		score: 50,
	},
	roosterfemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"절제하는 차가운 관계이다. 서로의 관계에 있어 문제들이 많으며 기본적으로 서로에 대해 적대시한다. 기껏해야 단지 서로 참는 것이다.",
		score: 50,
	},
	roostermalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"상호 이해관심의 영역이 많다. 서로의 성격을 깊이 이해하지는 못하지만 필요할 땐 협조한다.",
		score: 75,
	},
	roostermalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"상호 이해관심의 영역이 많다. 서로의 성격을 깊이 이해하지는 못하지만 필요할 땐 협조한다.",
		score: 75,
	},
	roosterfemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"상호 이해관심의 영역이 많다. 서로의 성격을 깊이 이해하지는 못하지만 필요할 땐 협조한다.",
		score: 75,
	},
	roosterfemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=534467&cid=46670&categoryId=46670",
		notes:
			"상호 이해관심의 영역이 많다. 서로의 성격을 깊이 이해하지는 못하지만 필요할 땐 협조한다.",
		score: 75,
	},
	dogmaledogmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=565808&cid=46670&categoryId=46670",
		notes:
			"마음이 잘 맞고 협력하는 관계이다. 갈등이 없다. 공통의 목표를 위해 우호적이며 서로 협력한다.",
		score: 75,
	},
	dogmaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=565808&cid=46670&categoryId=46670",
		notes:
			"마음이 잘 맞고 협력하는 관계이다. 갈등이 없다. 공통의 목표를 위해 우호적이며 서로 협력한다.",
		score: 75,
	},
	dogfemaledogfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=565808&cid=46670&categoryId=46670",
		notes:
			"마음이 잘 맞고 협력하는 관계이다. 갈등이 없다. 공통의 목표를 위해 우호적이며 서로 협력한다.",
		score: 75,
	},
	dogmalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=565808&cid=46670&categoryId=46670",
		notes:
			"괜찮은 관계이다. 심각한 성격 차이는 없다. 주도권 다툼이 없다. 어느 정도까지 용납할 수 있고 동의 할 수 있다.",
		score: 75,
	},
	dogmalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=565808&cid=46670&categoryId=46670",
		notes:
			"괜찮은 관계이다. 심각한 성격 차이는 없다. 주도권 다툼이 없다. 어느 정도까지 용납할 수 있고 동의 할 수 있다.",
		score: 75,
	},
	dogfemalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=565808&cid=46670&categoryId=46670",
		notes:
			"괜찮은 관계이다. 심각한 성격 차이는 없다. 주도권 다툼이 없다. 어느 정도까지 용납할 수 있고 동의 할 수 있다.",
		score: 75,
	},
	dogfemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=565808&cid=46670&categoryId=46670",
		notes:
			"괜찮은 관계이다. 심각한 성격 차이는 없다. 주도권 다툼이 없다. 어느 정도까지 용납할 수 있고 동의 할 수 있다.",
		score: 75,
	},
	pigmalepigmale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=544683&cid=46670&categoryId=46670",
		notes:
			"근본적인 성격충돌과 주도권 다툼이 있다. 차이점을 해소하려 애쓰지 않는다. 매우 부조화롭다.",
		score: 50,
	},
	pigmalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=544683&cid=46670&categoryId=46670",
		notes:
			"근본적인 성격충돌과 주도권 다툼이 있다. 차이점을 해소하려 애쓰지 않는다. 매우 부조화롭다.",
		score: 50,
	},
	pigfemalepigfemale: {
		reference:
			"https://terms.naver.com/entry.naver?docId=544683&cid=46670&categoryId=46670",
		notes:
			"근본적인 성격충돌과 주도권 다툼이 있다. 차이점을 해소하려 애쓰지 않는다. 매우 부조화롭다.",
		score: 50,
	},
};

export default zodiacSignJson;
