const constellationJson = {
	AriesmaleAriesmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesmaleAriesfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesfemaleAriesfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesmaleTaurusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	AriesmaleTaurusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	AriesfemaleTaurusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	AriesfemaleTaurusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	AriesmaleGeminimale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesmaleGeminifemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesfemaleGeminimale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	AriesfemaleGeminifemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesmaleCancermale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	AriesmaleCancerfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	AriesfemaleCancermale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	AriesfemaleCancerfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	AriesmaleLeomale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesmaleLeofemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesfemaleLeomale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesfemaleLeofemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesmaleVirgomale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesmaleVirgofemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesfemaleVirgomale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	AriesfemaleVirgofemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesmaleLibramale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesmaleLibrafemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesfemaleLibramale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	AriesfemaleLibrafemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesmaleScorpiusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	AriesmaleScorpiusfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	AriesfemaleScorpiusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	AriesfemaleScorpiusfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	AriesmaleSagittariusmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesmaleSagittariusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesfemaleSagittariusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	AriesfemaleSagittariusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	AriesmaleCapricornusmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	AriesmaleCapricornusfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	AriesfemaleCapricornusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	AriesfemaleCapricornusfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	AriesmaleAquariusmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesmaleAquariusfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesfemaleAquariusmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesfemaleAquariusfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesmalePiscesmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	AriesmalePiscesfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	AriesfemalePiscesmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AriesfemalePiscesfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	TaurusmaleTaurusmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	TaurusmaleTaurusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	TaurusfemaleTaurusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	TaurusmaleGeminimale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	TaurusmaleGeminifemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	TaurusfemaleGeminimale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	TaurusfemaleGeminifemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	TaurusmaleCancermale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	TaurusmaleCancerfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	TaurusfemaleCancermale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	TaurusfemaleCancerfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	TaurusmaleLeomale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	TaurusmaleLeofemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	TaurusfemaleLeomale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	TaurusfemaleLeofemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	TaurusmaleVirgomale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	TaurusmaleVirgofemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	TaurusfemaleVirgomale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	TaurusfemaleVirgofemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	TaurusmaleLibramale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	TaurusmaleLibrafemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	TaurusfemaleLibramale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	TaurusfemaleLibrafemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	TaurusmaleScorpiusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	TaurusmaleScorpiusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	TaurusfemaleScorpiusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	TaurusfemaleScorpiusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	TaurusmaleSagittariusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	TaurusmaleSagittariusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	TaurusfemaleSagittariusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	TaurusfemaleSagittariusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	TaurusmaleCapricornusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	TaurusmaleCapricornusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	TaurusfemaleCapricornusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	TaurusfemaleCapricornusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	TaurusmaleAquariusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	TaurusmaleAquariusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	TaurusfemaleAquariusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	TaurusfemaleAquariusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	TaurusmalePiscesmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	TaurusmalePiscesfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	TaurusfemalePiscesmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	TaurusfemalePiscesfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	GeminimaleGeminimale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	GeminimaleGeminifemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	GeminifemaleGeminifemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	GeminimaleCancermale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	GeminimaleCancerfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	GeminifemaleCancermale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	GeminifemaleCancerfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	GeminimaleLeomale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	GeminimaleLeofemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	GeminifemaleLeomale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	GeminifemaleLeofemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	GeminimaleVirgomale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	GeminimaleVirgofemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	GeminifemaleVirgomale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	GeminifemaleVirgofemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	GeminimaleLibramale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	GeminimaleLibrafemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	GeminifemaleLibramale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	GeminifemaleLibrafemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	GeminimaleScorpiusmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	GeminimaleScorpiusfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	GeminifemaleScorpiusmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	GeminifemaleScorpiusfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	GeminimaleSagittariusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	GeminimaleSagittariusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	GeminifemaleSagittariusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	GeminifemaleSagittariusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	GeminimaleCapricornusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	GeminimaleCapricornusfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	GeminifemaleCapricornusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	GeminifemaleCapricornusfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	GeminimaleAquariusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	GeminimaleAquariusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	GeminifemaleAquariusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	GeminifemaleAquariusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	GeminimalePiscesmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	GeminimalePiscesfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	GeminifemalePiscesmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	GeminifemalePiscesfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	CancermaleCancermale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	CancermaleCancerfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	CancerfemaleCancerfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	CancermaleLeomale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancermaleLeofemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancerfemaleLeomale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	CancerfemaleLeofemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancermaleVirgomale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	CancermaleVirgofemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	CancerfemaleVirgomale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	CancerfemaleVirgofemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	CancermaleLibramale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	CancermaleLibrafemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	CancerfemaleLibramale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	CancerfemaleLibrafemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	CancermaleScorpiusmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	CancermaleScorpiusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	CancerfemaleScorpiusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	CancerfemaleScorpiusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	CancermaleSagittariusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancermaleSagittariusfemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancerfemaleSagittariusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancerfemaleSagittariusfemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancermaleCapricornusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	CancermaleCapricornusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	CancerfemaleCapricornusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	CancerfemaleCapricornusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	CancermaleAquariusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancermaleAquariusfemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancerfemaleAquariusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancerfemaleAquariusfemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	CancermalePiscesmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	CancermalePiscesfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	CancerfemalePiscesmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	CancerfemalePiscesfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LeomaleLeomale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LeomaleLeofemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LeofemaleLeofemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LeomaleVirgomale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	LeomaleVirgofemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	LeofemaleVirgomale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	LeofemaleVirgofemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	LeomaleLibramale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeomaleLibrafemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeofemaleLibramale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	LeofemaleLibrafemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeomaleScorpiusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	LeomaleScorpiusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	LeofemaleScorpiusmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	LeofemaleScorpiusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	LeomaleSagittariusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LeomaleSagittariusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LeofemaleSagittariusmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	LeofemaleSagittariusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LeomaleCapricornusmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeomaleCapricornusfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeofemaleCapricornusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	LeofemaleCapricornusfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeomaleAquariusmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeomaleAquariusfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeofemaleAquariusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	LeofemaleAquariusfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeomalePiscesmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeomalePiscesfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	LeofemalePiscesmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	LeofemalePiscesfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	VirgomaleVirgomale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	VirgomaleVirgofemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	VirgofemaleVirgofemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	VirgomaleLibramale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	VirgomaleLibrafemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	VirgofemaleLibramale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	VirgofemaleLibrafemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	VirgomaleScorpiusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	VirgomaleScorpiusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	VirgofemaleScorpiusmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	VirgofemaleScorpiusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	VirgomaleSagittariusmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	VirgomaleSagittariusfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	VirgofemaleSagittariusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	VirgofemaleSagittariusfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	VirgomaleCapricornusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	VirgomaleCapricornusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	VirgofemaleCapricornusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	VirgofemaleCapricornusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	VirgomaleAquariusmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	VirgomaleAquariusfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	VirgofemaleAquariusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	VirgofemaleAquariusfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	VirgomalePiscesmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	VirgomalePiscesfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	VirgofemalePiscesmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	VirgofemalePiscesfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	LibramaleLibramale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LibramaleLibrafemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LibrafemaleLibrafemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	LibramaleScorpiusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	LibramaleScorpiusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	LibrafemaleScorpiusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	LibrafemaleScorpiusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	LibramaleSagittariusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	LibramaleSagittariusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	LibrafemaleSagittariusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	LibrafemaleSagittariusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	LibramaleCapricornusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	LibramaleCapricornusfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	LibrafemaleCapricornusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	LibrafemaleCapricornusfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	LibramaleAquariusmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	LibramaleAquariusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	LibrafemaleAquariusmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	LibrafemaleAquariusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	LibramalePiscesmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	LibramalePiscesfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	LibrafemalePiscesmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	LibrafemalePiscesfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	ScorpiusmaleScorpiusmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	ScorpiusmaleScorpiusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	ScorpiusfemaleScorpiusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	ScorpiusmaleSagittariusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	ScorpiusmaleSagittariusfemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	ScorpiusfemaleSagittariusmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	ScorpiusfemaleSagittariusfemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	ScorpiusmaleCapricornusmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	ScorpiusmaleCapricornusfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	ScorpiusfemaleCapricornusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	ScorpiusfemaleCapricornusfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	ScorpiusmaleAquariusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	ScorpiusmaleAquariusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	ScorpiusfemaleAquariusmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	ScorpiusfemaleAquariusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	ScorpiusmalePiscesmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	ScorpiusmalePiscesfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	ScorpiusfemalePiscesmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	ScorpiusfemalePiscesfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	SagittariusmaleSagittariusmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	SagittariusmaleSagittariusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	SagittariusfemaleSagittariusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	SagittariusmaleCapricornusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	SagittariusmaleCapricornusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	SagittariusfemaleCapricornusmale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	SagittariusfemaleCapricornusfemale: {
		score: 75,
		notes:
			"얼핏 보기에 서로 닮았다고 생각해 사랑에 빠지는 일이 많지만 일단 교제를 시작하고 나면 ‘이럴 리가 없었다며’ 서로에 대해 의문을 품게 되다. 이는 두 사람의 닮은 점이 행동 패턴이나 패션 센스 등 겉으로 드러나는 부분에 한하기 때문이다. 가치관 등 근본적인 부분은 정반대라고 해도 과언이 아닐 정도로 결과적으로는 사사건건 부딪치게 되고 주도권 싸움을 벌일지도. 서로 ‘닮은 꼴’이라는 생각 때문에 자신을 굽히려고 하지 않는 데 어려움이 있다. 주의할 점은 상대방 기분이나 상황 이야기를 제대로 듣지 않고 멋대로 모든 것을 판단하는 두 사람. 여행을 가는 등 이벤트가 있을 때마다 한바탕 싸움이 일어날 듯. 개중에는 피로연을 어떻게 할 것인지에 대해 이야기하다 싸움으로 번져 파혼하는 수도 생긴다. 두 사람 모두 ‘그(그녀)에 대해서는 자신이 가장 잘 알고 있다’는 생각을 버리려고 노력한다면 좋은 방향으로 갈 것이다.",
		reference: "reference",
	},
	SagittariusmaleAquariusmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	SagittariusmaleAquariusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	SagittariusfemaleAquariusmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	SagittariusfemaleAquariusfemale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	SagittariusmalePiscesmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	SagittariusmalePiscesfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	SagittariusfemalePiscesmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	SagittariusfemalePiscesfemale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	CapricornusmaleCapricornusmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	CapricornusmaleCapricornusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	CapricornusfemaleCapricornusfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	CapricornusmaleAquariusmale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	CapricornusmaleAquariusfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	CapricornusfemaleAquariusmale: {
		score: 38,
		notes:
			"서로의 다른 점에 끌리는 커플. 둘 다 자신에게 없는 매력에 이끌려 신선하다고 느끼고 있겠지만 실은 ‘자신이 되고 싶어하는 이상형’을 상대방에게서 발견하고 사랑에 빠지는 케이스가 대부분이다. 뒤집어 말하면 자기애로 인해 성립되는 관계를 말한다. 그러므로 교제가 진행되면서 자신이 생각하는 이상형과 다른 부분이 보이면 점차 상대방에게 실망하게 된다. 궁합 자체는 나쁘지 않지만 말다툼이나 냉전이 계속될 우려가. 서로의 공통점을 소중히 하면 잘 풀려나갈 것이다. 주의할 점은 자신이 할 수 없는 것을 상대방에게 요구하는 두 사람. 특히 상대방 친구와 어울리거나 가족에게 소개되어졌을 때 그 경향이 현저하다. 그러므로 친구나 가족이 없어지는 순간 두 사람은 서로를 책망하게 된다. 두 사람은 서로에게 요구하는 것 자체가 금물. 상대를 바꾸려고 하기보다 먼저 자신이 바뀌어야 한다.",
		reference: "reference",
	},
	CapricornusfemaleAquariusfemale: {
		score: 25,
		notes:
			"두 사람은 보기에는 완전히 정반대의 타입이다. 주위에서도 ‘어떻게 저 두 사람이 사귈까’라고 볼 정도로 이색적인 커플. 하지만 두 사람은 의외로 좋은 궁합. 서로의 결점을 보충하기 위해 약간의 트러블이나 문제가 생겨도 처음부터 ‘나는 나, 그는 그’라며 서로의 개성을 존중하기 때문에 의견 차이가 있어도 심각하게 대립하지는 않는다. 사고방식이나 가치관이 전혀 다르기 때문에 ‘이심전심’은 기대할 수 없지만 서로의 기분을 확실하게 이야기하면 별탈 없는 잉꼬부부가 될 것이다. 두 사람이 위기를 맞이하게 되는 때는 둘 중 한 명이 일이나 인간 관계에서 심하게 상처를 받을 때. 사고 방식이나 느끼는 법이 달라 무엇 때문에 상처를 받는지 어떻게 위로하면 좋을지 모른다. 어줍잖은 말로 상대방의 상처를 더 깊게 만들 수도. 이럴 때는 아무말도 하지 말고 지켜보는 것이 무난하다.",
		reference: "reference",
	},
	CapricornusmalePiscesmale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	CapricornusmalePiscesfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	CapricornusfemalePiscesmale: {
		score: 63,
		notes:
			"성별이 다르다면 남자가 여자에게 깊이 반해 있는 상태. 그는 자신이 반해 있다는 이유만으로 당신 행동에 철저하게 맞추려 들고 어떤 결점이라도 눈을 감아줄 것이다. 그와 있으면 당신은 마음껏 행동할 수 있고 솔직하게 자신을 표현하는 법에 대해 배우게 된다. 당신이 그를 리드하게 되면 원만한 결혼 생활을 보낼 수 있다. 반대로 당신이 그에게 무리해 맞추려고 하면 오히려 관계가 어긋나기 쉽다. 특히 그가 5세 이상 연상이라면 이런 경우가 나타나기 쉬우므로 주의를. 두 사람이 위기를 맞는 경우는 어떤 문제로 당신이 자신감을 상실했을 때. 그가 부드럽게 대해주면 당신은 ‘내게 그럴 가치가 있을까’라며 비굴해진다. 데이트나 섹스를 할 때도 되는 대로 아무렇게나 행동을 해 그를 상처 입히게 된다. 다시 말해 당신의 기분에 따라 두 사람의 관계는 좋아지기도 나빠지기도 한다. 그것을 명심하도록.",
		reference: "reference",
	},
	CapricornusfemalePiscesfemale: {
		score: 50,
		notes:
			"무의식중에 한 사람이 리드를 하고 한 사람이 내조를 하는 역할 분담이 확실한 커플. 두 사람이 어떤 일을 하면 최강의 콤비가 될 수 있다. 특히 결혼 생활은 어떤 의미에서 공동작업이라고 할 수 있으므로 두 사람이라면 행복한 가정을 꾸려나갈 수 있다. 보기에는 약간 보수적이고 따분해 보일 수 있지만 두 사람은 행복할 것이다. 그가 당신보다 나이가 훨씬 많다면 불만 없는 원만한 부부관계를 만들 수 있을 것이다. 반대로 동갑내기라면 자칫 주도권 싸움을 벌일 수 있지만 그가 포용력이 있는 타입이라면 문제 없다. 주의할 점은 당신이 조용히 내조를 하지 못할 때가 두 사람의 위기. 당신은 그가 하는 일마다 미주알고주알 잔소리를 하게 되거나 그는 불끈하며 당신을 억누르려고 하는 최악의 사태가 일어날 수도. 제3자에게 중재를 부탁하면 더욱 엉망이 되므로 둘이서 대화로 푸는 수밖에 다른 방법이 없다.",
		reference: "reference",
	},
	AquariusmaleAquariusmale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	AquariusmaleAquariusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	AquariusfemaleAquariusfemale: {
		score: 100,
		notes:
			"두 사람은 연애 타입이나 가치관이 동일하다. 둘 다 인간인 이상 세세한 부분에서는 틀리지만 근본적인 면에서는 일치하므로 전혀 걱정할 필요가 없다. 약간의 말다툼이나 싸움을 해도 이는 서로를 더 깊게 이해하기 위해 필요한 단계이다. 이 이상의 커플은 없다고 해도 과언이 아닐 정도로 좋은 궁합이다. 하지만 이런 혜택을 받고 있어도 그것을 잃기 전까지는 소중함을 모르는 것이 인간. 두 사람의 파장이 일치하기 때문에 생기는 평화를 지루하다고 잘못 받아들일우려가 있다. 주의할 점은 행복에 젖어 그 고마움을 잊기 쉬운 두 사람. 변화나 자극을 원해 일부러 다른 이성 이야기를 꺼내거나 상대방의 과거를 탐색하기도 하고 개중에는 정말 바람을 피우는 사람도 있다. 만약 그런 상황이 결혼 후에 일어난다면 행복의 절정에서 갑자기 지옥으로 떨어지게 될 수 있으므로 자중하도록 한다.",
		reference: "reference",
	},
	AquariusmalePiscesmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	AquariusmalePiscesfemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	AquariusfemalePiscesmale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	AquariusfemalePiscesfemale: {
		score: 13,
		notes:
			"솔직히 말해 두 사람은 물과 기름 같은 관계이다. 맞는 부분이 하나도 없고 사랑에 빠지는 일 자체가 기적이라고 할 수 있을 정도. 함께 있어도 파장이 맞지 않아 안타까워하거나 불안해한다. 뭔가 요구를 하면 완전히 틀린 대답이나 반응을 보인다. 가령 지금 두 사람 사이가 잘 진행되고 있어도 그것은 오로지 호기심이나 우연. 어느 정도 서로에 대해 알게 되고 호기심이 충족되면 그 다음은 자연히 소멸될 운이다. 그래도 그와 결혼하고 싶다면 자신의 개성을 바꾸겠다는 각오가 있어야 할 것이다. 주의할 점은 장애 투성이, 위기 투성이인 두 사람이지만 그보다 더 심각한 것이 속궁합이 맞지 않다는 것. 가끔씩 침대에 들 때에는 좋지만 동거를 하거나 결혼을 하게 되면 밤이 두려울 수도 있다.",
		reference: "reference",
	},
	PiscesmalePiscesmale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	PiscesmalePiscesfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
	PiscesfemalePiscesfemale: {
		score: 88,
		notes:
			"행동이나 사랑하는 방법이 아주 닮은 두 사람. 데이트 코스나 사랑을 속삭이는 법, 응답하는 법 등 서로의 기호가 꼭 맞기 때문에 마음 편한 관계를 쌓아나갈 수 있다. 하지만 비슷한 면이 너무 많아 결점이나 약점, 서투른 면까지 같은 것이 문제. 트러블이 생기거나 사건에 휘말렸을 때 두 사람이 함께 우울증에 걸리거나 서로 상대방이 무능한 것을 탓하기도 한다. 평화스러울 때는 좋지만 때로는 위기 상황에도 대처해야 하는 결혼 생활에서는 상당한 노력이 필요하다. 주의할 점은 싫어하는 사람까지 똑같은 두 사람이 맞이하는 위기는 대인 트러블. 처음에는 서로 협력해 잘 해결해 나가려고 하지만 결국 서로의 단점 때문에 계속 부딪치게 된다. 두 사람끼리 해결하려 들지 말고 반드시 제3자의 조언을 들을 것. 평상시 비상 시기에 상담역이 되어줄 수 있는 친구를 확실하게 정해놓도록.",
		reference: "reference",
	},
};

export default constellationJson;
