import constellationJson from "../json/constellationJson";

function getConstellation(month, day) {
	let cons;
	if (month === 1) {
		if (day >= 20) {
			cons = "Aquarius";
		} else cons = "Capricornus";
	}

	if (month === 2) {
		if (day >= 19) {
			cons = "Pisces";
		} else cons = "Aquarius";
	}

	if (month === 3) {
		if (day >= 21) {
			cons = "Aries";
		} else cons = "Pisces";
	}

	if (month === 4) {
		if (day >= 20) {
			cons = "Taurus";
		} else cons = "Aries";
	}

	if (month === 5) {
		if (day >= 21) {
			cons = "Gemini";
		} else cons = "Taurus";
	}

	if (month === 6) {
		if (day >= 22) {
			cons = "Cancer";
		} else cons = "Gemini";
	}

	if (month === 7) {
		if (day >= 23) {
			cons = "Leo";
		} else cons = "Cancer";
	}

	if (month === 8) {
		if (day >= 23) {
			cons = "Virgo";
		} else cons = "Leo";
	}

	if (month === 9) {
		if (day >= 24) {
			cons = "Libra";
		} else cons = "Virgo";
	}

	if (month === 10) {
		if (day >= 23) {
			cons = "Scorpius";
		} else cons = "Libra";
	}

	if (month === 11) {
		if (day >= 23) {
			cons = "Sagittarius";
		} else cons = "Scorpius";
	}

	if (month === 12) {
		if (day >= 25) {
			cons = "Capricornus";
		} else cons = "Sagittarius";
	}
	return cons;
}

const ConstellationTypeScore = (me, you) => {
	let me_born_month = parseInt(String(me.birth).slice(4, 6));
	let me_born_day = parseInt(String(me.birth).slice(6, 8));
	let you_born_month = parseInt(String(you.birth).slice(4, 6));
	let you_born_day = parseInt(String(you.birth).slice(6, 8));

	let constellation1 = getConstellation(me_born_month, me_born_day);
	let constellation2 = getConstellation(you_born_month, you_born_day);
	let result =
		constellationJson[
			`${constellation1}${me.gender}${constellation2}${you.gender}`
		];

	if (!result) {
		result =
			constellationJson[
				`${constellation2}${you.gender}${constellation1}${me.gender}`
			];
	}

	return result;
};

export default ConstellationTypeScore;
