import zodiacSignJson from "../json/zodiacSignJson";

const zodiacSignTypeScore = (me, you) => {
	const zodiac_arr = [
		"monkey",
		"rooster",
		"dog",
		"pig",
		"mouse",
		"ox",
		"tiger",
		"rabbit",
		"dragon",
		"snake",
		"horse",
		"lamb",
	];
	// 하이루 하이루^^ 19930830
	const me_born_year = parseInt(String(me.birth).slice(0, 5));
	const you_born_year = parseInt(String(you.birth).slice(0, 5));
	const zodiacSign1 = zodiac_arr[me_born_year % 12];
	const zodiacSign2 = zodiac_arr[you_born_year % 12];

	let result =
		zodiacSignJson[`${zodiacSign1}${me.gender}${zodiacSign2}${you.gender}`];
	if (!result) {
		result =
			zodiacSignJson[`${zodiacSign2}${you.gender}${zodiacSign1}${me.gender}`];
	}

	return result;
};

export default zodiacSignTypeScore;
