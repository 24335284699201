import bloodJson from "../json/bloodJson";

const bloodTypeScore = (me, you) => {
	let check = 0;
	let result = bloodJson[`${me.blood}${me.gender}${you.blood}${you.gender}`];
	if (!result) {
		result = bloodJson[`${you.blood}${you.gender}${me.blood}${me.gender}`];
		if (!result) {
			result = bloodJson[`${you.blood}male${me.blood}female`];
			if (!result) {
				result = bloodJson[`${you.blood}female${me.blood}male`];
			}
		}
		check = 1;
	}

	return [result, check];
};

export default bloodTypeScore;
